import React from "react";
import PageLayout from "../components/PageLayout";

const SiteStatusPage = () => {
  return (
    <PageLayout>
      <div style={{ marginLeft: "32px" }}>
        <h1 style={{ marginTop: "96px" }}>Site Status</h1>
        <img
          src="https://api.netlify.com/api/v1/badges/48d7e6a3-cd95-489e-96d4-2d0236d5af42/deploy-status"
          alt="site status"
        />
      </div>
    </PageLayout>
  );
};

export default SiteStatusPage;
